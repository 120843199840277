import React from 'react'
import styled from 'styled-components'

export const PageWrap = ({ children }) => <Container>{children}</Container>

const Container = styled.main`
    padding: 0 20px;
    margin: 0 auto;
    max-width: calc(1440px + 260px);

    @media (min-width: 768px) {
        padding: 0 130px;
        padding: 0 40px;
        //width: calc(1440px + 80px);
    }
`
