import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Nav } from './nav'

const Header = ({ siteTitle }) => (
    <Container>
        <Nav />
    </Container>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header

const Container = styled.header`
    padding: 40px 40px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        display: none;
    }
`
