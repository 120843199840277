import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

export const Nav = () => (
    <Container>
        <ul>
            <li>
                <Link to="/#explorer">Explorer</Link>
            </li>
            <li>
                <Link to="/#faq">FAQs</Link>
            </li>
            <li>
                <Link to="/#newsletter">Receive Updates</Link>
            </li>
        </ul>
    </Container>
)

const Container = styled.nav`
    ul {
        display: flex;
        list-style: none;
        padding: 0;

        li {
            font-size: 18px;

            a {
                color: inherit;
                opacity: 1;
                font-size: 18px;
                font-weight: 500;
                transition: color 0.35s ease-in-out;

                &:hover {
                    color: #66A3B0;

                }
            }

            & + li {
                margin-left: 150px;
            }
        }
    }
`
