import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

export const Heading = ({ children, asElement = 'h2', size = 'large', mb, className }) => (
    <Container size={size} as={asElement} mb={mb} className={className}>
        {children}
    </Container>
)

const SIZES = {
    small: '20px',
    medium: '50px',
    large: '65px',
}

const SIZES_MOBILE = {
    small: '20px',
    medium: '28px',
    large: '38px',
}

Heading.defaultProps = {
    size: 'large',
}

const Container = styled.h2`
    font-size: ${props => SIZES[props.size]};
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: ${props => props.mb || '20px'};

    @media (max-width: 768px) {
        font-size: ${props => SIZES_MOBILE[props.size]};
    }
`
