import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Nav } from './nav'
import StarkwareLogoImage from '../assets/starkware-logo.svg'
import Logo from '../assets/zrx-logo.svg'

const Footer = ({ siteTitle }) => (
    <Container>
        <div>
            <Copy>starkdex.io is built and powered by</Copy>
            <Logos>
                <Link href="https://0x.org">
                    <Logo />
                </Link>
                <Link href="https://www.starkware.co/">
                    <StarkwareLogo />
                </Link>
            </Logos>
        </div>
    </Container>
)

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}

export default Footer

const Container = styled.footer`
    background-color: #f4fcfa;
    padding: 30px 20px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /*
  display: grid;
  grid-template-columns: 2fr 2fr 1.5fr;
  grid-column-gap: 160px;
  */
`

const Copy = styled.p`
    font-size: 0.75rem;
    max-width: 335px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
        font-size: 1rem;
    }
`

const Logos = styled.div`
    display: flex;
    justify-content: center;
`

const Link = styled.a.attrs({
    target: '_blank',
})``

const StarkwareLogo = styled(StarkwareLogoImage)`
    margin-left: 40px;
`
