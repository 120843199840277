/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import { PageWrap } from './page_wrap'

// import "./layout.css"
// import "./global_style.css"
//import GlobalStyle from 'components/global_style'

const Layout = ({ children }) => (
    <>
        {/* <GlobalStyle /> */}
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <PageWrap>{children}</PageWrap>
                    <Footer siteTitle={data.site.siteMetadata.title} />
                </>
            )}
        />
    </>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
